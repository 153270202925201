<template>
    <div 
        class="
            w-full flex flex-row rounded mb-4 text-left 
            bg-gray-100  px-8 py-4
            md:bg-gray-100 md:hover:bg-white md:px-8 md:py-4
        "
        @click="$emit('edit', customer);">
        
        
        <div class="flex-1">
            <h2 class="text-black mt-1">
                <span v-if="customer.isCompany">{{customer.name}}</span>
                <span v-else-if="primaryContact">{{primaryContact.name}}</span>
            </h2>
        </div>
        
        <div 
            v-if="primaryContact"
            class="flex-1 max-w-sm">
            
            <h4>{{$t('customers.contactPerson')}}</h4>
            
            <div 
                v-if="primaryContact.name" 
                class="truncate">
                {{primaryContact.name}}
            </div>
            
            <div 
                v-if="primaryContact.email" 
                class="truncate">
                
                {{primaryContact.email}}
            </div>
            
            <div 
                v-if="primaryContact.phone" 
                class="truncate">
                
                {{primaryContact.phone}}
            </div>
        </div>
        
        <div class="flex-1 max-w-sm">
            <div v-if="customer.street">
                
                <h4>{{$t('customers.address')}}</h4>
                {{customer.street}}<br>
                {{customer.postcode}} {{customer.city}}<br>
            </div>
        </div>
        
        <div class="flex-1">
            <h4 v-if="customer.isCompany">
                <div class="inline-block w-3 h-3 rounded-full bg-cyan "></div>
                {{$t('customers.company')}}
            </h4>
            <h4 v-else>
                <div class="inline-block w-3 h-3 rounded-full bg-green-500 "></div>
                {{$t('customers.personal')}}
            </h4>
            
            <div class="leading-none">
                {{$t('general.created')}} {{ customer.createdAt | moment('from') }}
            </div>
        </div>
        
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customer: {
            type: Object,
            required: true,
        }
    },
    computed: {
        primaryContact(){
            if (
                this.customer.contacts && 
                this.customer.contacts.length) {
                
                return this.customer.contacts.find(c => c.isPrimary) || this.customer.contacts[0];
            }
            else {
                return null
            }
        }
    }
}
</script>

<style lang="scss" >

</style>
